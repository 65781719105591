var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm rpi", attrs: { title: "상세" } },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          isSubmit: _vm.isSave,
                          url: _vm.saveUrl,
                          param: _vm.assessPlan,
                          mappingType: _vm.mappingType,
                          label: "저장",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveAssessPlan,
                          btnCallback: _vm.saveCallback,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
              [
                _c("c-text", {
                  attrs: {
                    required: true,
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "평가명",
                    name: "assessmentName",
                  },
                  model: {
                    value: _vm.assessPlan.assessmentName,
                    callback: function ($$v) {
                      _vm.$set(_vm.assessPlan, "assessmentName", $$v)
                    },
                    expression: "assessPlan.assessmentName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-datepicker", {
                  attrs: {
                    required: true,
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    range: true,
                    label: "평가기간",
                    name: "period",
                  },
                  model: {
                    value: _vm.assessPlan.assessmentPeriod,
                    callback: function ($$v) {
                      _vm.$set(_vm.assessPlan, "assessmentPeriod", $$v)
                    },
                    expression: "assessPlan.assessmentPeriod",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-datepicker", {
                  attrs: {
                    required: true,
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    default: "today",
                    type: "year",
                    label: "평가년도",
                    name: "assessmentYear",
                  },
                  model: {
                    value: _vm.assessPlan.assessmentYear,
                    callback: function ($$v) {
                      _vm.$set(_vm.assessPlan, "assessmentYear", $$v)
                    },
                    expression: "assessPlan.assessmentYear",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-select", {
                  attrs: {
                    required: true,
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    codeGroupCd: "RAM_ASSESS_TYPE_CD",
                    type: "edit",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "ramAssessTypeCd",
                    label: "평가구분-정기/수시/최초",
                  },
                  model: {
                    value: _vm.assessPlan.ramAssessTypeCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.assessPlan, "ramAssessTypeCd", $$v)
                    },
                    expression: "assessPlan.ramAssessTypeCd",
                  },
                }),
              ],
              1
            ),
            _vm.assessPlan.ramTechniqueCd !== "RT00000005" &&
            _vm.assessPlan.ramTechniqueCd !== "RT00000010" &&
            _vm.assessPlan.ramTechniqueCd !== "RT00000035"
              ? _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-select", {
                      attrs: {
                        required: true,
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        comboItems: _vm.matrixItems,
                        type: "edit",
                        itemText: "matrixName",
                        itemValue: "ramMatrixId",
                        name: "ramMatrixId",
                        label: "Matrix",
                      },
                      model: {
                        value: _vm.assessPlan.ramMatrixId,
                        callback: function ($$v) {
                          _vm.$set(_vm.assessPlan, "ramMatrixId", $$v)
                        },
                        expression: "assessPlan.ramMatrixId",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
              [
                _c("c-moc", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    isSubmit: _vm.saveCallData,
                    document: _vm.assessPlan,
                    documentId: _vm.assessPlan.ramRiskAssessmentPlanId
                      ? _vm.assessPlan.ramRiskAssessmentPlanId
                      : _vm.ramRiskAssessmentPlanId,
                    documentTitle: "assessmentName",
                    mocRelatedTaskCd: "RT00000005",
                    label: "MOC번호",
                    name: "sopMocId",
                  },
                  on: {
                    "update:document": function ($event) {
                      _vm.assessPlan = $event
                    },
                  },
                  model: {
                    value: _vm.assessPlan.sopMocId,
                    callback: function ($$v) {
                      _vm.$set(_vm.assessPlan, "sopMocId", $$v)
                    },
                    expression: "assessPlan.sopMocId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-field", {
                  attrs: {
                    required: true,
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    data: _vm.assessPlan,
                    deptValue: "assessmentManageDeptCd",
                    type: "dept_user",
                    label: "주관",
                    name: "assessmentManageUserId",
                  },
                  model: {
                    value: _vm.assessPlan.assessmentManageUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.assessPlan, "assessmentManageUserId", $$v)
                    },
                    expression: "assessPlan.assessmentManageUserId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-plant", {
                  attrs: {
                    required: true,
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    type: "edit",
                    name: "plantCd",
                  },
                  model: {
                    value: _vm.assessPlan.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.assessPlan, "plantCd", $$v)
                    },
                    expression: "assessPlan.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    label: "작성자/작성일",
                    name: "request",
                  },
                  model: {
                    value: _vm.request,
                    callback: function ($$v) {
                      _vm.request = $$v
                    },
                    expression: "request",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-vendor", {
                  attrs: {
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    label: "외부기관",
                    name: "vendorCd",
                  },
                  model: {
                    value: _vm.assessPlan.vendorCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.assessPlan, "vendorCd", $$v)
                    },
                    expression: "assessPlan.vendorCd",
                  },
                }),
              ],
              1
            ),
            _vm.assessPlan.ramTechniqueCd === "RT00000015"
              ? _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        label: "작업지역",
                        name: "workArea",
                      },
                      model: {
                        value: _vm.assessPlan.workArea,
                        callback: function ($$v) {
                          _vm.$set(_vm.assessPlan, "workArea", $$v)
                        },
                        expression: "assessPlan.workArea",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                class: [
                  _vm.assessPlan.ramTechniqueCd === "RT00000015"
                    ? "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
                    : "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                ],
              },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "상세내용",
                    name: "remark",
                  },
                  model: {
                    value: _vm.assessPlan.remark,
                    callback: function ($$v) {
                      _vm.$set(_vm.assessPlan, "remark", $$v)
                    },
                    expression: "assessPlan.remark",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-field", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    data: _vm.assessPlan,
                    type: "user",
                    label: "검토자",
                    name: "reviewUserId",
                  },
                  model: {
                    value: _vm.assessPlan.reviewUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.assessPlan, "reviewUserId", $$v)
                    },
                    expression: "assessPlan.reviewUserId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-field", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    data: _vm.assessPlan,
                    type: "user",
                    label: "승인자",
                    name: "approvalUserId",
                  },
                  model: {
                    value: _vm.assessPlan.approvalUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.assessPlan, "approvalUserId", $$v)
                    },
                    expression: "assessPlan.approvalUserId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2" },
              [
                _c("c-construnction", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    name: "sopConstructionId",
                  },
                  model: {
                    value: _vm.assessPlan.sopConstructionId,
                    callback: function ($$v) {
                      _vm.$set(_vm.assessPlan, "sopConstructionId", $$v)
                    },
                    expression: "assessPlan.sopConstructionId",
                  },
                }),
              ],
              1
            ),
            _vm.isOcc && _vm.isOld
              ? _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                  },
                  [
                    _c(
                      "c-table",
                      {
                        ref: "table2",
                        attrs: {
                          title: "수시 변경/추가 내역",
                          columns: _vm.gridOcc.columns,
                          gridHeight: _vm.gridOcc.height,
                          data: _vm.assessPlan.occasions,
                          filtering: false,
                          columnSetting: false,
                          usePaging: false,
                          editable: _vm.editable && !_vm.disabled,
                          selection: "multiple",
                          rowKey: "ramAssessmentOccasionId",
                        },
                      },
                      [
                        _c(
                          "template",
                          { slot: "table-button" },
                          [
                            _c(
                              "q-btn-group",
                              { attrs: { outline: "" } },
                              [
                                _vm.editable && !_vm.disabled
                                  ? _c("c-btn", {
                                      attrs: {
                                        showLoading: false,
                                        label: "추가",
                                        icon: "add",
                                      },
                                      on: { btnClicked: _vm.addOccasion },
                                    })
                                  : _vm._e(),
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.assessPlan.occasions &&
                                _vm.assessPlan.occasions.length > 0
                                  ? _c("c-btn", {
                                      attrs: { label: "삭제", icon: "remove" },
                                      on: {
                                        btnClicked: _vm.deleteAssessOccasion,
                                      },
                                    })
                                  : _vm._e(),
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.assessPlan.occasions &&
                                _vm.assessPlan.occasions.length > 0
                                  ? _c("c-btn", {
                                      attrs: {
                                        isSubmit: _vm.isSaveOccasion,
                                        url: _vm.saveOccasionUrl,
                                        param: _vm.assessPlan.occasions,
                                        mappingType: "PUT",
                                        label: "저장",
                                        icon: "save",
                                      },
                                      on: {
                                        beforeAction: _vm.saveAssessOccasion,
                                        btnCallback: _vm.saveOccasionCallback,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isOld
              ? _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7",
                  },
                  [
                    _c(
                      "c-table",
                      {
                        ref: "table1",
                        attrs: {
                          title: "평가팀 목록",
                          columns: _vm.grid.columns,
                          gridHeight: _vm.grid.height,
                          data: _vm.assessPlan.teams,
                          filtering: false,
                          columnSetting: false,
                          usePaging: false,
                          editable: _vm.editable && !_vm.disabled,
                          selection: "multiple",
                          rowKey: "ramAssessmentTeamId",
                        },
                      },
                      [
                        _c(
                          "template",
                          { slot: "table-button" },
                          [
                            _c(
                              "q-btn-group",
                              { attrs: { outline: "" } },
                              [
                                _vm.editable && !_vm.disabled
                                  ? _c("c-btn", {
                                      attrs: {
                                        showLoading: false,
                                        label: "추가",
                                        icon: "add",
                                      },
                                      on: { btnClicked: _vm.addTeam },
                                    })
                                  : _vm._e(),
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.assessPlan.teams &&
                                _vm.assessPlan.teams.length > 0
                                  ? _c("c-btn", {
                                      attrs: { label: "삭제", icon: "remove" },
                                      on: { btnClicked: _vm.deleteAssessTeam },
                                    })
                                  : _vm._e(),
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.assessPlan.teams &&
                                _vm.assessPlan.teams.length > 0
                                  ? _c("c-btn", {
                                      attrs: {
                                        isSubmit: _vm.isSaveTeam,
                                        url: _vm.saveTeamUrl,
                                        param: _vm.assessPlan.teams,
                                        mappingType: "PUT",
                                        label: "저장",
                                        icon: "save",
                                      },
                                      on: {
                                        beforeAction: _vm.saveAssessTeam,
                                        btnCallback: _vm.saveTeamCallback,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
              [
                _c("c-upload", {
                  staticClass: "q-pt-md",
                  attrs: {
                    attachInfo: _vm.attachInfo,
                    editable: _vm.editable && !_vm.disabled,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }