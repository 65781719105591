<template>
  <q-form ref="editForm">
    <c-card title="상세" class="cardClassDetailForm rpi">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="assessPlan"
            :mappingType="mappingType"
            label="저장" 
            icon="save"
            @beforeAction="saveAssessPlan"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-text
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            label="평가명"
            name="assessmentName"
            v-model="assessPlan.assessmentName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            :range="true"
            label="평가기간"
            name="period"
            v-model="assessPlan.assessmentPeriod"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            default="today"
            type="year"
            label="평가년도"
            name="assessmentYear"
            v-model="assessPlan.assessmentYear"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            codeGroupCd="RAM_ASSESS_TYPE_CD"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="ramAssessTypeCd"
            label="평가구분-정기/수시/최초"
            v-model="assessPlan.ramAssessTypeCd"
          ></c-select>
        </div>
        <!-- K-PSR, Check-list, 3단계판단법은 표시하지 않음 -->
        <div v-if="assessPlan.ramTechniqueCd !== 'RT00000005' && assessPlan.ramTechniqueCd !== 'RT00000010' && assessPlan.ramTechniqueCd !== 'RT00000035'" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            :comboItems="matrixItems"
            type="edit"
            itemText="matrixName"
            itemValue="ramMatrixId"
            name="ramMatrixId"
            label="Matrix"
            v-model="assessPlan.ramMatrixId"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-moc 
            :editable="editable"
            :disabled="disabled"
            :isSubmit="saveCallData"
            :document.sync="assessPlan"
            :documentId="assessPlan.ramRiskAssessmentPlanId ? assessPlan.ramRiskAssessmentPlanId : ramRiskAssessmentPlanId"
            documentTitle="assessmentName"
            mocRelatedTaskCd="RT00000005"
            label="MOC번호"
            name="sopMocId"
            v-model="assessPlan.sopMocId"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-field 
            :required="true" 
            :editable="editable"
            :disabled="disabled"
            :data="assessPlan"
            deptValue="assessmentManageDeptCd"
            type="dept_user" 
            label="주관" 
            name="assessmentManageUserId" 
            v-model="assessPlan.assessmentManageUserId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant 
            :required="true" 
            :disabled="disabled"
            :editable="editable" 
            type="edit" 
            name="plantCd" 
            v-model="assessPlan.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            :disabled="true"
            label="작성자/작성일"
            name="request"
            v-model="request">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-vendor
            :disabled="disabled"
            :editable="editable"
            label="외부기관"
            name="vendorCd"
            v-model="assessPlan.vendorCd" />
        </div>
        <div v-if="assessPlan.ramTechniqueCd === 'RT00000015'" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="작업지역"
            name="workArea"
            v-model="assessPlan.workArea">
          </c-text>
        </div>
        <div :class="[assessPlan.ramTechniqueCd === 'RT00000015' ? 'col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4' : 'col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6']">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="상세내용"
            name="remark"
            v-model="assessPlan.remark">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-field 
            :editable="editable"
            :disabled="disabled"
            :data="assessPlan"
            type="user" 
            label="검토자" 
            name="reviewUserId" 
            v-model="assessPlan.reviewUserId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-field 
            :editable="editable"
            :disabled="disabled"
            :data="assessPlan"
            type="user" 
            label="승인자" 
            name="approvalUserId" 
            v-model="assessPlan.approvalUserId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-construnction
            :editable="editable"
            :disabled="disabled"
            name="sopConstructionId"
            v-model="assessPlan.sopConstructionId">
          </c-construnction>
        </div>
        <div v-if="isOcc&&isOld" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="table2"
            title="수시 변경/추가 내역"
            :columns="gridOcc.columns"
            :gridHeight="gridOcc.height"
            :data="assessPlan.occasions"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            :editable="editable&&!disabled"
            selection="multiple"
            rowKey="ramAssessmentOccasionId"
          >
            <!-- 버튼 영역 -->
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn 
                  v-if="editable&&!disabled" 
                  :showLoading="false" 
                  label="추가" 
                  icon="add" 
                  @btnClicked="addOccasion" />
                <c-btn 
                  v-if="editable&&!disabled&&assessPlan.occasions&&assessPlan.occasions.length>0" 
                  label="삭제" 
                  icon="remove" 
                  @btnClicked="deleteAssessOccasion" />
                <c-btn 
                  v-if="editable && !disabled&&assessPlan.occasions&&assessPlan.occasions.length>0" 
                  :isSubmit="isSaveOccasion"
                  :url="saveOccasionUrl"
                  :param="assessPlan.occasions"
                  mappingType="PUT"
                  label="저장" 
                  icon="save"
                  @beforeAction="saveAssessOccasion"
                  @btnCallback="saveOccasionCallback" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div v-if="isOld" class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
          <c-table
            ref="table1"
            title="평가팀 목록"
            :columns="grid.columns"
            :gridHeight="grid.height"
            :data="assessPlan.teams"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            :editable="editable&&!disabled"
            selection="multiple"
            rowKey="ramAssessmentTeamId"
          >
            <!-- 버튼 영역 -->
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn 
                  v-if="editable&&!disabled" 
                  :showLoading="false" 
                  label="추가" 
                  icon="add" 
                  @btnClicked="addTeam" />
                <c-btn 
                  v-if="editable&&!disabled && assessPlan.teams && assessPlan.teams.length>0" 
                  label="삭제" 
                  icon="remove" 
                  @btnClicked="deleteAssessTeam" />
                <c-btn 
                  v-if="editable && !disabled && assessPlan.teams && assessPlan.teams.length>0" 
                  :isSubmit="isSaveTeam"
                  :url="saveTeamUrl"
                  :param="assessPlan.teams"
                  mappingType="PUT"
                  label="저장" 
                  icon="save"
                  @beforeAction="saveAssessTeam"
                  @btnCallback="saveTeamCallback" /> 
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <c-upload 
            class="q-pt-md"
            :attachInfo="attachInfo"
            :editable="editable&&!disabled">
          </c-upload>
        </div>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'riskPlanInfo',
  props: {
    assessPlan: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        plantCd: '',  // 사업장 코드
        ramTechniqueCd: '',  // 평가기법 코드_HAZOP/KRAS/JSA/CHECKLIST
        ramAssessTypeCd: '',  // 평가구분_최초/정기/수시
        assessmentName: '',  // 평가명
        assessmentStartDate: '',  // 평가시작일
        assessmentEndDate: '',  // 평가종료일
        assessmentPeriod: [],
        assessmentYear: '',  // 평가년도
        ramStepCd: '',  // 평가 진행단계 코드
        assessmentManageDeptCd: '',  // 평가주관 부서 코드
        assessmentManageUserId: '',  // 평가주관 담당자 ID
        ramMatrixId: null,  // 위험메트릭스 번호
        sopMocId: '',  // MOC 일련번호
        remark: '',  // 상세내용
        workArea: '',  // 작업지역
        regDtStr: '',
        regUserId: '',  // 작성자 ID
        regUserName: '',  // 작성자 ID
        reviewUserId: '',  // 검토자 ID
        reviewDate: '',  // 검토일
        approvalUserId: '',  // 승인자 ID
        approvalDate: '',  // 승인일
        vendorCd: '',
        sopConstructionId: '',
        chgUserId: '',  // 수정자 ID
        teams: [], // 평가팀 목록
        delTeams: [],
        occasions: [], // 수시 변경/추가 내역 목록
        conferences: [], // 회의 목록
        relatedDocuments: [], // 관련자료 목록
        processes: [], // 대상공정 목록
      }),
    },
    attachInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'RISK_PLAN',
        taskKey: '',
      }),
    },
    vendorFlag: {
      type: Boolean,
      default: () => ({
        flag: false,
      }),
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
    disabled2: {
      type: Boolean,
      default: function() {
        return false;
      }
    },
  },
  data() {
    return {
      matrixItems: [],
      grid: {
        columns: [
          {
            name: 'ramRoleTypeCd',
            field: 'ramRoleTypeCd',
            label: '역할',
            align: 'center',
            style: 'width:20%',
            type: 'select',
            codeGroupCd: 'RAM_ROLE_TYPE_CD',
            sortable: false,
            required: true,
          },
          {
            name: 'etcRole',
            field: 'etcRole',
            label: `기타 역할<div style="font-size:0.9em">(역할을 '기타'로 선택 시)</div>`,
            align: 'left',
            style: 'width:20%',
            type: 'text',
            sortable: false,
            disableTarget: 'ramRoleTypeCd',
            disableCon: 'RRT0000010',
          },
          {
            name: 'ramInsideOutsideCd',
            field: 'ramInsideOutsideCd',
            label: '내외부구분',
            align: 'center',
            style: 'width:20%',
            type: 'select',
            codeGroupCd: 'COM_INSIDE_OUTSIDE_CD',
            none: true,
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '이름',
            align: 'center',
            style: 'width:20%',
            type: !this.vendorFlag ? 'user' : 'text',
            userId: 'userId',
            sortable: false,
            isInout: true,
            inoutCol: 'ramInsideOutsideCd',
            in: '1',
            out: '2',
            relationCols: ['deptCd', 'deptName'],
            required: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            style: 'width:20%',
            sortable: false,
          }
        ],
        height: '250px'
      },
      gridOcc: {
        columns: [
          {
            name: 'taskClassName',
            field: 'taskClassName',
            label: '업무',
            align: 'center',
            style: 'width:15%',
            sortable: false,
          },
          {
            name: 'taskTitle',
            field: 'taskTitle',
            label: '제목',
            align: 'left',
            style: 'width:30%',
            sortable: false,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '업무',
            align: 'left',
            style: 'width:55%',
            type: 'textarea',
            sortable: false,
          },
        ],
        height: 'auto'
      },
      editable: true,
      matrixListUrl: '',
      saveUrl: '',
      teamListUrl: '',
      saveTeamUrl: '',
      deleteTeamUrl: '',
      occasionListUrl: '',
      saveOccasionUrl: '',
      deleteOccasionUrl: '',
      isSave: false,
      isSaveTeam: false,
      isSaveOccasion: false,
      mappingType: 'POST',
      ramRiskAssessmentPlanId: '',
      saveCallData: '',
      popupOptions: {
        isApproval: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  watch: {
    'assessPlan.plantCd'() {
      this.setMatrixItems(); 
    }
  },
  computed: {
    isOld() {
      return Boolean(this.assessPlan.ramRiskAssessmentPlanId)
    },
    isOcc() {
      return this.assessPlan.ramAssessTypeCd === 'RAT0000005'
    },
    request() {
      return this.assessPlan.regUserName + ' / ' + this.assessPlan.regDtStr.slice(0, 10)
    },
    techCheck() {
      // HAZOP, CHARM만 기능 활성화
      return false
      // return this.assessPlan.ramTechniqueCd === 'RT00000001'
      //   || this.assessPlan.ramTechniqueCd === 'RT00000030'
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.matrixListUrl = selectConfig.ram.matrix.list.url
      this.saveUrl = transactionConfig.ram.assessPlan.insert.url
      this.teamListUrl = selectConfig.ram.assessTeam.list.url;
      this.saveTeamUrl = transactionConfig.ram.assessTeam.update.url;
      this.deleteTeamUrl = transactionConfig.ram.assessTeam.delete.url;
      this.occasionListUrl = selectConfig.ram.assessOccasion.list.url;
      this.saveOccasionUrl = transactionConfig.ram.assessOccasion.update.url;
      this.deleteOccasionUrl = transactionConfig.ram.assessOccasion.delete.url;
      // code setting
      // this.getTeams();
      this.setMatrixItems(); 
      // this.setTeamHeader(); 
      // list setting
    },
    setMatrixItems() {
      // K-PSR은 위험도 측정을 하지 않음
      if (this.assessPlan.ramTechniqueCd === 'RT00000005') return
      this.$http.url = this.matrixListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.assessPlan.plantCd,
        ramTechniqueCd: this.assessPlan.ramTechniqueCd,
      }
      this.$http.request((_result) => {
        this.matrixItems = _result.data;
      },);
    },
    getTeams() {
      this.$http.url = this.teamListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.assessPlan.ramRiskAssessmentPlanId
      }
      this.$http.request((_result) => {
        this.assessPlan.teams = _result.data
      },);
    },
    getOccasions() {
      this.$http.url = this.occasionListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.assessPlan.ramRiskAssessmentPlanId
      }
      this.$http.request((_result) => {
        this.assessPlan.occasions = _result.data
      },);
    },
    loadAssessPlan() {
      this.popupOptions.title = "불러오기"; 
      this.popupOptions.param = {
        type: "single",
        ramTechniqueCd: this.assessPlan.ramTechniqueCd,
        // regUserId: this.$store.getters.user.userId
      };
      this.popupOptions.target = () => import(`${"@/pages/ram/loadAssessmentPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeLoadPopup;
    },
    closeLoadPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$emit('loadAssess', data[0].ramRiskAssessmentPlanId)
      }
    },
    saveAssessPlan() {
      if (this.assessPlan.ramRiskAssessmentPlanId) {
        this.saveUrl = transactionConfig.ram.assessPlan.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.ram.assessPlan.insert.url
        this.mappingType = 'POST';
        this.assessPlan.ramStepCd = 'RRS0000001' // 평가계획
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result && this.$comm.validTable(this.grid.columns, this.assessPlan.teams)) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.assessPlan.regUserId = this.$store.getters.user.userId
              this.assessPlan.chgUserId = this.$store.getters.user.userId

              this.assessPlan.assessmentStartDate = this.assessPlan.assessmentPeriod[0]
              this.assessPlan.assessmentEndDate = this.assessPlan.assessmentPeriod[1]

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.ramRiskAssessmentPlanId = result.data
      this.$set(this.attachInfo, 'taskKey', result.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.saveCallData = uid();
      this.$emit('saveCallback', result.data)
    },
    addOccasion() {
      this.popupOptions.title = '변경/추가 내역 검색'; 
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/riskOccasion.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeOccasionPopup;
    },
    closeOccasionPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.assessPlan.occasions, { 
            taskId: item.taskId, 
            taskClassName: item.taskClassName 
          }) === -1) {
            this.assessPlan.occasions.splice(0, 0, { 
              ramAssessmentOccasionId: uid(),  // 수시 변경/추가 내역 번호
              ramRiskAssessmentPlanId: this.assessPlan.ramRiskAssessmentPlanId,  // 평가 일련번호
              taskClassName: item.taskClassName,  // 설비, 기계기구, 공정, 화학자재, 사고
              taskId: item.taskId,  // 업무 id
              taskTitle: item.taskTitle,  // 업무 제목
              remarks: '',  // 비고
              editFlag: 'C', regUserId: this.$store.getters.user.userId,
            })
          }
        })
      }
    },
    deleteAssessOccasion() {
      let selectData = this.$refs['table2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$http.url = this.deleteOccasionUrl;
        this.$http.type = 'DELETE';
        this.$http.param = {
          data: Object.values(selectData),
        };
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          
          this.$_.forEach(selectData, item => {
            this.assessPlan.occasions = this.$_.reject(this.assessPlan.occasions, item);
          })
          this.$refs['table2'].$refs['compo-table'].clearSelection();
        },);
      }
    },
    saveAssessOccasion() {
      if (this.$comm.validTable(this.gridOcc.columns, this.assessPlan.occasions)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.assessPlan.occasions, item => {
              item.chgUserId = this.$store.getters.user.userId
            })

            this.isSaveOccasion = !this.isSaveOccasion
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveOccasionCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getOccasions();
    },
    addTeam() {
      this.assessPlan.teams.push({
        ramAssessmentTeamId: uid(),
        ramInsideOutsideCd: '1',
        ramRoleTypeCd: null, userId: '', userName: '', deptName: '', etcRole: '',
        editFlag: 'C', regUserId: this.$store.getters.user.userId,
        ramRiskAssessmentPlanId: this.assessPlan.ramRiskAssessmentPlanId
      })
    },
    deleteAssessTeam() {
      let selectData = this.$refs['table1'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        if (!this.assessPlan.delTeams) this.assessPlan.delTeams = [];
        this.$_.forEach(selectData, item => {
          if (this.$_.findIndex(this.assessPlan.delTeams, { ramAssessmentTeamId: item.ramAssessmentTeamId }) === -1
            && item.editFlag !== 'C') {this.assessPlan.delTeams.push(item)}
          this.assessPlan.teams = this.$_.reject(this.assessPlan.teams, item);
        })
        this.$refs['table1'].$refs['compo-table'].clearSelection();
      }
    },
    saveAssessTeam() {
      if (this.$comm.validTable(this.grid.columns, this.assessPlan.teams)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.assessPlan.teams, item => {
              item.chgUserId = this.$store.getters.user.userId
            })

            this.isSaveTeam = !this.isSaveTeam
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveTeamCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getTeams();
    },
  }
};
</script>